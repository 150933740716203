<template>
    <div>
        <JPrint ref="doPrint"></JPrint>
        <a-form-model layout="inline" :model="form">
            <a-form-model-item label="">
                <a-select style="width: 160px" v-model="form.status">
                    <a-select-option value=''>全部样品</a-select-option>
                    <a-select-option value='1'>未留样</a-select-option>
                    <a-select-option value='2'>已留样</a-select-option>
                    <a-select-option value='5'>样品退回</a-select-option>
                    <a-select-option value='6'>拒收样品</a-select-option>
                    <a-select-option value='8'>样品销毁</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form.spec_types" :options="Samplecategory_list" placeholder="请选择"/>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-input placeholder="请输入样品名称编号进行搜索" style="width: 260px" v-model="form.keywords"/>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-button type="danger" @click="Clear()">清空</a-button>
            </a-form-model-item>
            <a-form-model-item label="">
                <div class="print-tag">
                    <a-button type="primary">打印选中样品编号</a-button>
                    <div class="print-show">
                        <div v-for="(item,index) in templateList" :key="item.id"> {{ item.name }}
                            <img :src="getImg(item)" style="width:150px">
                            <a-button type="primary" @click="PrintTAG(index)">打 印</a-button>
                        </div>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item label="" style="float: right">
                <a-button type="primary">导出筛选数据</a-button>
            </a-form-model-item>
        </a-form-model>
        <a-col :span="24">
            <table class="layui-table" lay-size="sm">
                <thead>
                <tr>
                    <th>样品类型</th>
                    <th @click="yinziclickup(1)">
                        <span class="fl">样品名称</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                    </th>
                    <th @click="yinziclickup(2)">
                        <span class="fl">样品编号</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                    </th>
                    <th>采集信息</th>
                    <th>接收信息</th>
                    <th @click="yinziclickup(3)">
                        <span class="fl">样品状态</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                    </th>
                    <th>留样</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in GGet_list_list">
                    <td>{{ item.type_name }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                        <a-space>
                            <a-checkbox v-model="item.check"></a-checkbox>
                            {{ item.in_number }}
                        </a-space>
                    </td>
                    <td>
                        <P>采集人：{{ item.caiji_name }}</P>
                        <P>采集时间：{{ item.pick_time }}</P>
                        <P>采集地点：{{ item.pick_space }}</P>
                        <P>采集经纬度：{{ item.jing_dot }},{{ item.wei_dot }}</P>
                    </td>
                    <td>
                        <p>接收人：{{ item.jieshou_name }}</p>
                        <p>接收时间：{{ item.get_time }}</p>
                    </td>
                    <td>
                        <p>{{ item.status_trun }}</p>
                    </td>
                    <td>
                        <p>{{ item.liuyang_status_trun }}</p>
                        <template v-if="item.liuyang_status_trun === 2">
                            <p>留样时间：{{ item.reserve_time }}</p>
                            <p>到期时间：{{ item.expire_time }}</p>
                            <p>备注：</p>
                        </template>
                    </td>
                    <td>
                        <a-space>
                            <a-button type="primary" @click="showDrawerE(item)" v-if=" item.status != 3 && item.status != 4">
                                编辑样品
                            </a-button>
                            <a-button type="primary" @click="Set_specitem_lingqu(item)" v-if="item.status_lingqu === 2">
                                领取任务
                            </a-button>
                            <a-button @click="iSet_spec_item(item)" v-if="item.status_lingqu === 1 && item.status ==2">
                                采集完成
                            </a-button>
                            <a-button type="primary" @click="showDrawerAccept(item)" v-if="item.status === 3">接收样品
                            </a-button>
                            <a-button type="primary" @click="showDrawerRefuse(item)" v-if="item.status === 3">拒收样品
                            </a-button>
                            <a-button type="primary" @click="showDrawerReserve(item)" v-if="item.status === 4 && item.liuyang_status===1">
                                样品留样
                            </a-button>
                            <a-button type="danger" @click="showDrawerSendBack(item)" v-if="item.status === 4">
                                退回样品
                            </a-button>
                            <a-button @click="showDrawerB(item)" v-if="item.record_status === 1 ">拒收退回记录</a-button>
                            <!--                                <a-button type="danger" @click="showDrawerDestroy">样品销毁</a-button>-->
                            <a-button @click="showDrawerT(item)">样品流转信息</a-button>
                        </a-space>
                        <!--                            <div class="message">-->
                        <!--                                <template v-for="(iitem,key0) in item.spec_receive">-->
                        <!--                                    <p v-if="iitem.type === 1" @click="showDrawerApply(iitem)">-->
                        <!--                                        {{ iitem.receive_name }}：{{ iitem.create_time }}申请领取样品</p>-->
                        <!--                                    <p v-if="iitem.type === 2" @click="showDrawerSendBacki(iitem)">-->
                        <!--                                        {{ iitem.receive_name }}：{{ iitem.create_time }}退回了样品</p>-->
                        <!--                                </template>-->
                        <!--                            </div>-->

                    </td>
                </tr>
                </tbody>
            </table>
            <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
        </a-col>
        </a-row>

        <!--编辑样品1---------- 1-采集待处理， 2-采集中，--------->
        <div v-if="edit_info">
            <EditSample :edit_info="edit_info" :edit_time="edit_time" :close="onCloseDrawerE" :visible="drawer_visibleE"></EditSample>
        </div>

        <!--接收 2---------- 3-采集完成，--------->
        <div v-if="sample_info">
            <Accept :sample_info="sample_info" :sample_time="sample_time" :close="onCloseDrawerAccept" :visible="drawer_visibleAccept"></Accept>
        </div>

        <!--拒收 3---------- 3-采集完成，--------->
        <div v-if="Refuse_info">
            <Refuse :Refuse_info="Refuse_info" :Refuse_time="Refuse_time" :close="onCloseDrawerRefuse" :visible="drawer_visibleRefuse"></Refuse>
        </div>

        <!--退回 4---------- 4-接收完成，--------->
        <div v-if="SendBack_info">
            <SendBack :SendBack_info="SendBack_info" :SendBack_time="SendBack_time" :close="onCloseDrawerSendBack" :visible="drawer_visibleSendBack"></SendBack>
        </div>

        <!--退回记录 1-->
        <div v-if="send_info">
            <SendBacklist :send_info="send_info" :close="onCloseDrawerB" :visible="drawer_visibleB"></SendBacklist>
        </div>

        <!--留样 6-->
        <div v-if="Reserve_info">
            <ReserveSample :Reserve_info="Reserve_info" :close="onCloseDrawerReserve" :visible="drawer_visibleReserve"></ReserveSample>
        </div>

        <!--时间轴 2-->
        <div v-if="info">
            <TimeLine :info="info" :close="onCloseDrawerT" :visible="drawer_visibleT"></TimeLine>
        </div>

        <!--销毁 7-->
        <Destroy :close="onCloseDrawerDestroy" :visible="drawer_visibleDestroy"></Destroy>

        <!--确认申领消息 8-->
        <div v-if="Apply_info">
            <Apply :Apply_info="Apply_info" :Apply_time="Apply_time" :close="onCloseDrawerApply" :visible="drawer_visibleApply"></Apply>
        </div>

        <!--确认退回消息-->
        <div v-if="SendBacki_info">
            <SendBacki :SendBacki_info="SendBacki_info" :SendBacki_time="SendBacki_time" :close="onCloseDrawerSendBacki" :visible="drawer_visibleSendBacki"></SendBacki>
        </div>


    </div>
</template>

<script>
    import SendBacklist from "../caijiauth/sendback-list/sendback-list";
    import SendBacki from "../jieshouauth/confirm-sendback/confirm-sendback.vue";
    import ReserveSample from "../jieshouauth/reserve-sample/reserve-sample.vue";
    import Refuse from "../jieshouauth/refuse/refuse";
    import Destroy from "../jieshouauth/destroy/destroy";
    import SendBack from "../jieshouauth/send-back/send-back";
    import Accept from "../jieshouauth/accept/accept";
    import Apply from "../jieshouauth/apply/apply";
    import TimeLine from "../jieshouauth/time-line/time-line";
    import JPrint from "@/components/print/JPrint";
    import Print from "@/utils/print";
    import EditSample from "../caijiauth/edit-sample/edit-sample";

    export default {
        components: {
            Destroy,
            TimeLine,
            Apply,
            Accept,
            SendBack,
            Refuse,
            ReserveSample,
            SendBacki,
            JPrint,
            SendBacklist,
            EditSample
        },

        data() {
            return {

                Samplecategory_list: [],//样品类型文件夹列表
                page_show: false,
                page: 1,
                count: '',
                pagesize: '',
                spec_receive_list: [],
                GGet_list_list: [],
                form: {
                    status: '',
                    keywords: '',
                    spec_type: '',
                    spec_types: [],
                },

                // 定义控制抽屉显示的变量
                Reserve_time: 0,
                Reserve_info: false,
                drawer_visibleReserve: false,//留样

                Refuse_time: 0,
                Refuse_info: false,
                drawer_visibleRefuse: false,//拒收

                drawer_visibleDestroy: false,//销毁

                SendBacki_time: 0,
                SendBacki_info: false,
                drawer_visibleSendBacki: false,//退回

                SendBack_time: 0,
                SendBack_info: false,
                drawer_visibleSendBack: false,//退回

                sample_time: 0,
                sample_info: [],
                drawer_visibleAccept: false,//接收

                Apply_time: 0,
                Apply_info: false,
                drawer_visibleApply: false,//同意申领、拒绝申领

                info: false,
                drawer_visibleT: false,//流转时间轴

                send_info: false,
                drawer_visibleB: false,//拒收退回记录

                edit_time: 0,
                edit_info: false,
                drawer_visibleE: false,//编辑样品

                // 打印样品标签
                templateList: [],//标签模板
                print_value: [],
                ordertype: 1,
                sortrule: 'asc',

            }
        },
        watch: {
            form: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    if (this.page_show) this.mountedDo1()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            mountedDo1() {
                this.page = Number(this.$route.query.page) || 1;
                this.GGet_list();
                this.Samplecategoryalllist();
                setTimeout(() => {
                    this.page_show = true
                }, 100)
                this.taglist(3)
            },
            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.form.keywords = this.$route.query.keywords || '';
                this.form.status = this.$route.query.status || '';
                console.log('this.$route.query.spec_types', this.$route.query.spec_types)
                this.form.spec_types = this.$route.query.spec_types ? this.$route.query.spec_types.split(',').map((item) => {
                    return Number(item)
                }) : [];
                this.GGet_list();
                this.Samplecategoryalllist();
                setTimeout(() => {
                    this.page_show = true
                }, 100)
                this.taglist(3)
            },

            //搜索
            searchClick() {
                //console.log(this.form.keywords)
                this.$router.push({
                    query: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        spec_types: this.form.spec_types.join(','),
                        page: 1,
                    }
                })
            },
            Clear() {
                this.form.status = '';
                this.form.keywords = '';
                this.form.spec_types = [];
                this.page = 1;
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        keywords: this.form.keywords,
                        status: this.form.status,
                        spec_types: this.form.spec_types.join(','),
                    }
                })
            },

            taglist(id) {
                this.$sa0.post({
                    url: this.$api('Get_tag'),
                    data: {
                        tag_type: id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.templateList = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            PrintTAG(index) {
                let check_list = [];
                this.GGet_list.map((item) => {
                    if (item.check) {
                        check_list.push({demo_num: item.in_number, demo_text: item.name})
                    }
                })
                if (check_list.length === 0) {
                    layer.msg('请选择要打印的样品编号')
                } else {
                    //
                    let template = this.templateList[index]
                    let src_list = check_list.map(item => {
                        template.demo_num = item.demo_num
                        template.demo_text = item.demo_text
                        return Print.getImg(template)
                    })
                    console.log(check_list)
                    this.$refs.doPrint.printIt(src_list);
                }
            },
            //样品类型文件夹列表
            Samplecategoryalllist() {
                //console.log('---------------------------');
                this.$sa0.post({
                    url: this.$api('Samplecategoryalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Samplecategory_list = response.data.list;
                            // this.Samplecategory_list = JSON.parse(response.data.list).map((item)=>{
                            //     return item.toString()
                            // });
                            console.log(typeof (this.Samplecategory_list));
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Set_specitem_lingqu(item) {
                let data = {
                    id: item.id,
                    status: 2,
                }
                //console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_specitem_lingqu'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已领取任务')
                            this.GGet_list();
                            //this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            iSet_spec_item(item) {
                let data = {
                    id: item.id,
                    status: 3,
                }
                //console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_spec_item'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('采集完成')
                            this.GGet_list();
                            //this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            // w接受采集列表
            GGet_list() {
                this.$sa0.post({
                    url: this.$api('GGet_list'),
                    data: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        spec_type: this.form.spec_types[this.form.spec_types.length - 1],
                        page: this.page,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            //this.GGet_list_list = response.data.result.list;
                            this.GGet_list_list = response.data.result.list.map((item) => {
                                item.check = false
                                return item;
                            });
                            console.log(this.GGet_list_list);
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            yinziclickup(ordertype) {
                if (this.ordertype !== ordertype) {
                    this.ordertype = ordertype
                    this.sortrule = 'asc'
                } else {
                    if (this.sortrule === 'asc') {
                        this.sortrule = 'desc'
                    } else {
                        this.sortrule = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('GGet_list'),
                    data: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        spec_type: this.form.spec_types[this.form.spec_types.length - 1],
                        page: this.page,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.GGet_list_list = response.data.result.list.map((item) => {
                                item.check = false
                                return item;
                            });
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 关闭抽屉时的 函数 拒收退回记录
            showDrawerB(item) {
                this.send_info = item;
                //console.log(this.send_info);
                this.drawer_visibleB = true;
            },
            onCloseDrawerB() {
                this.drawer_visibleB = false;
                this.send_info = false
            },
            // 定义 打开抽屉时的 函数 留样
            showDrawerReserve(item) {
                this.Reserve_info = item;
                this.Reserve_time = new Date() / 1;
                this.drawer_visibleReserve = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerReserve() {
                this.GGet_list();
                this.drawer_visibleReserve = false;
                this.Reserve_info = false;
            },

            // 定义 打开抽屉时的 函数 拒收
            showDrawerRefuse(item) {
                this.Refuse_info = item;
                this.Refuse_time = new Date() / 1;
                this.drawer_visibleRefuse = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerRefuse() {
                this.GGet_list();
                this.drawer_visibleRefuse = false;
                this.Refuse_info = false;
            },

            // 定义 打开抽屉时的 函数 销毁
            showDrawerDestroy() {
                this.drawer_visibleDestroy = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerDestroy() {
                this.drawer_visibleDestroy = false;
            },

            // 定义 打开抽屉时的 函数 退回消息
            showDrawerSendBacki(iitem) {
                console.log(iitem);
                this.SendBacki_info = iitem;
                this.SendBacki_time = new Date() / 1;
                this.drawer_visibleSendBacki = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerSendBacki() {
                this.drawer_visibleSendBacki = false;
                this.SendBacki_info = false;
            },

            // 定义 打开抽屉时的 函数 退回
            showDrawerSendBack(item) {
                this.SendBack_info = item;
                this.SendBack_time = new Date() / 1;
                this.drawer_visibleSendBack = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerSendBack() {
                this.drawer_visibleSendBack = false;
                this.SendBack_info = false;
            },

            // 定义 打开抽屉时的 函数 接收
            showDrawerAccept(item) {
                this.sample_info = item;
                this.sample_time = new Date() / 1;
                this.drawer_visibleAccept = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerAccept() {
                this.GGet_list();
                this.drawer_visibleAccept = false;
                this.sample_info = false;
            },


            // 定义 打开抽屉时的 函数 申领
            showDrawerApply(iitem) {
                this.Apply_info = iitem;
                this.Apply_time = new Date() / 1;
                this.drawer_visibleApply = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawerApply() {
                this.GGet_list();
                this.drawer_visibleApply = false;
                this.Apply_info = false;
            },

            // 定义 打开抽屉时的 函数 时间轴
            showDrawerT(item) {
                this.info = item;
                console.log(this.info);
                this.drawer_visibleT = true;
            },
            onCloseDrawerT() {
                this.drawer_visibleT = false;
            },

            // 定义 关闭抽屉时的 函数  编辑样品
            showDrawerE(item) {
                this.edit_info = item;
                //console.warn(this.edit_info, this.edit_info)
                this.edit_time = new Date() / 1;
                this.drawer_visibleE = true;
            },
            onCloseDrawerE() {
                this.GGet_list();
                this.drawer_visibleE = false;
                this.edit_info = false
            },


            getImg(item) {
                return Print.getImg(item)
            }

        },
    }
</script>

<style scoped>
    .print-tag{
        position: relative;
        }

    .print-show{
        display: none
        }

    .print-tag:hover .print-show{
        display: block;
        position: absolute;
        top: 36px;
        left: 0;
        width: 260px;
        height: auto;
        border: solid 1px #efefef;
        background: #fff;
        padding: 15px;
        z-index: 999;
        }

    .message{
        padding-top: 15px
        }

    .message p:hover{
        cursor: pointer
        }

    .ant-table-column-sorter{
        display: flex;
        flex-direction: column;
        margin-top: 3px;
        margin-left: 5px;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }
</style>
