<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="编辑样品" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model :model="form">
          <a-form-model-item label="样品类型" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span:10, offset: 0 }">
            {{ edit_info.type_name }}
          </a-form-model-item>

          <a-form-model-item label="样品名称" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-input v-model="form.name"/>
          </a-form-model-item>

          <a-form-model-item label="样品编号" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-input v-model="form.in_number">
              <a-tooltip slot="suffix" title="使用默认编号" @click="nummoren()">
                <a-icon type="unordered-list" style="color: rgba(0,0,0,.45)"/>
              </a-tooltip>
            </a-input>
            <a-button type="primary" @click="showModal01()">使用其它编号</a-button>
          </a-form-model-item>

          <a-form-model-item label="采集时间" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-date-picker v-model="form.pick_time" show-time format="YYYY-MM-DD HH:mm:ss"
                           valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
          </a-form-model-item>

          <a-form-model-item label="采集地点" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-input v-model="form.pick_space"/>
          </a-form-model-item>
          <a-form-model-item label="采集经度" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-input v-model="form.jing_dot"/>
          </a-form-model-item>
          <a-form-model-item label="采集纬度" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 0 }">
            <a-input v-model="form.wei_dot"/>
          </a-form-model-item>

          <a-form-model-item label="" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 6 }">
            <a-space>
              <a-button @click="getXyClick()">获取经纬度</a-button>
            </a-space>

          </a-form-model-item>

          <a-form-model-item label="" :label-col="{ span: 6, offset: 0 }"
                             :wrapper-col="{ span: 10, offset: 6 }">
            <a-space>
              <a-button type="primary" @click="doClick()">保存</a-button>
              <a-button @click="doClick(1)">保存并标记采集完成</a-button>
            </a-space>

          </a-form-model-item>

        </a-form-model>
      </div>
    </a-drawer>
    <NumberrulelistYP :callback="pushOrder" :ok="modalClose01" :cancel="modalClose01"
                      :Numberrulelist="Numberrulelist"></NumberrulelistYP>
  </div>
</template>
<script>
/***
 Name: example3
 Code: sa0ChunLuyu
 Time: 2021/10/16 09:47
 Remark: 编辑抽屉示例的抽屉组件
 */
import NumberrulelistYP from './numberrulelist-YP/numberrulelist-YP.vue';//w选择其它编号规则
export default {
  components: {NumberrulelistYP,},
  // 接收 显示变量 和 关闭函数
  props: ['visible', 'close', 'edit_info', 'edit_time'],

  data() {

    return {
      Numberrulelist: false,
      Samplecategory_list: [],
      form: {
        id: '',
        spec_types: [],
        type_name: '',
        name: '',
        in_number: '',
        pick_time: '',
        pick_space: '',
        jing_dot: '',
        wei_dot: '',
      },
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.edit_info))
    this.form.spec_types = JSON.parse(this.form.spec_types)
    // spec_types.split(',').map((item) => {
    //     return Number(item)
    // })
    //this.form.spec_types = JSON.parse(this.form.spec_types)
  },
  methods: {

    getXyClick() {
      this.form.jing_dot = '110.46'
      this.form.wei_dot = '40.51'
    },
    doClick(typeNumber) {
      //console.log(typeNumber);
      let data = {
        id: this.form.id,
        // spec_type: this.form.spec_types[this.form.spec_types.length - 1],
        // spec_types: this.form.spec_types,
        type_name: this.form.type_name,
        name: this.form.name,
        in_number: this.form.in_number,
        pick_time: this.form.pick_time,
        pick_space: this.form.pick_space,
        jing_dot: this.form.jing_dot,
        wei_dot: this.form.wei_dot,
        type: typeNumber,
      }
      //console.log(data)
      //console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Edit_caijiitem'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg('已保存')
            this.close()
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },

    //默认委托单编号w
    nummoren() {
      this.$sa0.post({
        url: this.$api('Get_default_serialnumber'),
        data: {
          num_type: 1,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.form.in_number = response.data.serialnumber;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    pushOrder(order) {
      this.form.in_number = order
      this.modalClose01();
    },
    // 定义 打开对话框时的 函数 //W编号规则弹窗
    showModal01() {
      this.Numberrulelist = true
    },
    // 定义 关闭对话框时的 函数 //W编号规则弹窗
    modalClose01() {
      this.Numberrulelist = false
    },

    formatDateTime(inputTime) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },

    // 定义 组件 自身的关闭函数
    onClose() {
      this.close();
    },
  }
}
</script>
<style scoped>

</style>
