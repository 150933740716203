import { render, staticRenderFns } from "./numberrulelist-YP.vue?vue&type=template&id=685564f3&scoped=true&"
import script from "./numberrulelist-YP.vue?vue&type=script&lang=js&"
export * from "./numberrulelist-YP.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_cutmzj7votxcrccm4agyl3addi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685564f3",
  null
  
)

export default component.exports